import * as React from "react";
import {
	List, Show, Edit, Create,
	Datagrid, DatagridConfigurable, TabbedShowLayout, Tab, SimpleForm, SingleFieldList,
	TextField, DateField, FunctionField, ArrayField, WrapperField, ReferenceField,
	DateInput, NumberInput, TextInput, SelectInput, RadioButtonGroupInput, ReferenceInput, Form, FormDataConsumer, maxValue,
	TopToolbar, EditButton, DeleteButton, BulkDeleteButton, CreateButton, ExportButton, SelectColumnsButton, Button, Labeled,
	useNotify, useRedirect, useTranslate, required, useDataProvider, useRecordContext, useGetList, useGetOne, useListContext
} from 'react-admin';
import { useWatch } from 'react-hook-form';
import { JsonField } from "react-admin-json-view";
import moment from 'moment';
import {
	TablePagination,
	CreateToolbar,
	UpdateToolbar,
	DateTimeLocale,
	HasCreatePermission,
	HasUpdatePermission,
	HasDeletePermission,
	TextFieldWithCopyButton, ProviderFilter
} from '../shared';
import CardView from '../components/CardView';
import ConditionalTextField from '../components/ConditionalTextField';
import ImageField from '../components/ImageField';
import CurrencyChipField from '../components/CurrencyChipField';
import CurrencySelect from '../components/CurrencySelect';
import UserDefinedFieldsCreate from '../components/UserDefinedFieldsCreate';
import UserDefinedFieldsUpdate from '../components/UserDefinedFieldsUpdate';
import { Environment } from "../configProvider";
import CancelIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Downloading';
import { makeStyles } from '@mui/styles';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { HubConnectionBuilder } from '@microsoft/signalr';
import CurrencyFlag from 'react-currency-flags';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert'
import { FormatCardNumber, FormatCurrencyValue } from '../shared';
import Flag from 'react-world-flags';
import responseCodes from "../codes/ISO8583-1987-ResponseCodes.json";
import LaunchIcon from '@mui/icons-material/Launch';
import { Table, TableRow, TableCell } from '@mui/material'

const useShowStyles = makeStyles({
	headerCell: { paddingLeft: 0 },
	rowCell: { paddingLeft: 0 }
});

export const VirtualCardList = () => {
	const translate = useTranslate();

	const Actions = () => {
		const { total, isLoading } = useListContext();
		return (
			<TopToolbar>
				{HasCreatePermission('VirtualCards', '/virtual_card') && <CreateButton />}
				<ExportButton disabled={isLoading || total === 0} />
				<SelectColumnsButton />
			</TopToolbar>
		);
	};

	const StateField = ({label}) => {
		const record = useRecordContext();
		const { state } = record;

		let color = 'black';

		if (state && state.startsWith('Failed'))
			color = 'red'

		return <WrapperField label={label}><span style={{color}}>{state}</span></WrapperField>
	};

	const virtualCardFilter = [
		<TextInput label={`${translate('kuggar.shared.kuggar_id', Environment)} / ${translate('kuggar.virtual_cards.provider_id')}`} source="q" alwaysOn />,
		<ProviderFilter alwaysOn />,
		<SelectInput label={translate('kuggar.shared.state')} source="state" choices={[
			{ id: 'Updated', name: 'Updated' },
			{ id: 'Updating', name: 'Updating' },
			{ id: 'Cancelled', name: 'Cancelled' },
			{ id: 'Cancelling', name: 'Cancelling' },
			{ id: 'FailedToUpdate', name: 'Failed To Update' },
			{ id: 'FailedToCancel', name: 'Failed To Cancel' },
			{ id: 'Expired', name: 'Expired' },
			{ id: 'ExcludeCancelled', name: 'Hide Cancelled' }
		]} alwaysOn />,
		<DateInput label={translate('kuggar.virtual_cards.valid_from')} source="validFrom" alwaysOn />,
		<DateInput label={translate('kuggar.virtual_cards.valid_to')} source="validTo" alwaysOn />,
		<CurrencySelect label={translate('kuggar.shared.currency')} source="currencyCode" value="alphaCode" isFilter={true} alwaysOn />
	];

	const ListActionButtons = () => (
		<>
			<BulkDeleteButton
				label={translate('kuggar.virtual_cards.cancel_card_multi')}
				icon={<CancelIcon />}
				mutationMode="optimistic"
				confirmTitle={translate('kuggar.virtual_cards.cancel_card_multi')}
				confirmContent={translate('kuggar.virtual_cards.cancel_card_multi_confirmation')} />
		</>
	);

	return (
		<List actions={<Actions />} filters={virtualCardFilter} pagination={<TablePagination />} perPage={10} sort={{ field: 'CurrentCardProperties.DateTimeUtc', order: 'DESC' }} hasCreate={HasCreatePermission('VirtualCards', '/virtual_card')}>
			<DatagridConfigurable
				rowClick="show"
				bulkActionButtons={HasDeletePermission('VirtualCards', '/virtual_card') ? <ListActionButtons /> : null}
				isRowSelectable={record => record.state !== 'Cancelled'}
				omit={[
					"record.organisation.id",
					"issuerAccountId"
				]}
			>
				<TextField source="id" label={translate('kuggar.shared.kuggar_id', Environment)} />
				<FunctionField source="record.organisation.id" label={translate('kuggar.organisation.organisation')} render={record => record.organisation ? `${record.organisation.name} (${record.organisation.referenceCode})` : '-'} />
				<ReferenceField source="issuerAccountId" label={translate('kuggar.shared.issuer_account')} reference="issuer_account" link="show">
					<TextField source="description" />
				</ReferenceField>
				<ImageField source="provider" path="cards" height="50" label={translate('kuggar.virtual_cards.provider')} />
				<TextField source="currentCardProperties.providerId" label={translate('kuggar.virtual_cards.provider_id')} />
				<StateField label={translate('kuggar.virtual_cards.state')} />
				<FunctionField label={translate('kuggar.virtual_cards.card_number')} sortable={false} render={record => FormatCardNumber(record.currentCardProperties.cardNumber)} />
				<FunctionField label={translate('kuggar.virtual_cards.valid_from')} sortable={false} render={record => moment(record.currentCardProperties.validFrom).format('YYYY-MM-DD')} />
				<FunctionField label={translate('kuggar.virtual_cards.valid_to')} sortable={false} render={record => moment(record.currentCardProperties.validTo).format('YYYY-MM-DD')} />
				<FunctionField label={translate('kuggar.virtual_cards.card_limit')} sortable={false} render={record => {
					let currencyCode = record.currentCardProperties.currencyCodes[0];
					let cardLimit = record.currentCardProperties.cardLimit;
					return <span style={{verticalAlign:'top'}}><CurrencyFlag currency={currencyCode} size="md" style={{verticalAlign:'top'}} />&nbsp;{FormatCurrencyValue(currencyCode, cardLimit)}</span>;
				}} />
				<DateField source="currentCardProperties.dateTimeUtc" label={translate('kuggar.virtual_cards.last_refreshed')} showTime locales={DateTimeLocale} />
			</DatagridConfigurable>
		</List>
	);
};

export const VirtualCardShow = () => {
	const [connection, setConnection] = useState(null);
	const [cardState, setCardState] = useState(null);
	const [cardFailState, setCardFailState] = useState(null);
	const translate = useTranslate();
	const notify = useNotify();
	const redirect = useRedirect();
	const classes = useShowStyles();
	const dataProvider = useDataProvider();
	const [showRefreshAlert, setShowRefreshAlert] = useState(false);
	const [showCopyAlert, setShowCopyAlert] = useState(false);
	const [selectedCopyValue, setSelectedCopyValue] = useState(null);
	const { id } = useParams();

	useEffect(() => {
		if (!connection) {
			const token = localStorage.getItem('auth');
			const cloudRegion = localStorage.getItem('cloud-region');
			const cloudProvider = localStorage.getItem('cloud-provider')
				? localStorage.getItem('cloud-provider')
				: (Environment.MultiRegion === true ? Object.keys(Environment.API.Platform)[0] : "Azure");

			const hubsUrl = Environment.MultiRegion === true
				? (Environment.Hubs.VirtualCardUpdates[cloudProvider][cloudRegion]
					? Environment.Hubs.VirtualCardUpdates[cloudProvider][cloudRegion]
					: Environment.Hubs.VirtualCardUpdates[Object.keys(Environment.Hubs.VirtualCardUpdates)[0]][Object.keys(Environment.Hubs.VirtualCardUpdates[Object.keys(Environment.Hubs.VirtualCardUpdates)[0]])[0]])
				: Environment.Hubs.VirtualCardUpdates;

			const newConnection = new HubConnectionBuilder()
				.withUrl(hubsUrl, { accessTokenFactory: () => token })
				.withAutomaticReconnect()
				.build();

			setConnection(newConnection);
		}
		if (connection && connection.connectionState !== "Connected" && connection.connectionState !== "Connecting" ) {
			connection.start()
				.then(result => {
					console.log('Connected to Signal R Virtual Card hub. ');

					connection.on('ReceiveMessageAsync', message => {
						if (id === message.virtualCardId) {
							setCardFailState(null);

							let newCardState = {
								newState: message.state,
								newCardLimit: message.cardLimit,
								newAvailableBalance: message.availableBalance,
								newValidFrom: message.validFrom,
								newValidTo: message.validTo,
								newUserDefinedFields: message.userDefinedFields.slice(),
								newLastSynchDateUtc: message.lastRefreshhDateUtc
							};
							setCardState(newCardState);

							renderRefreshAlert();
						}
					});

					connection.on('ReceiveRefreshMessageAsync', message => {
						if (id === message.virtualCardId) {
							setCardFailState(null);

							let newCardState = {
								newState: message.state,
								newCardLimit: message.cardLimit,
								newAvailableBalance: message.availableBalance,
								newValidTo: message.validTo,
								newLastSynchDateUtc: message.lastRefreshhDateUtc
							};
							setCardState(newCardState);

							renderRefreshAlert();
						}
					});

					connection.on('ReceiveStateSetMessageAsync', message => {
						if (id === message.virtualCardId) {
							setCardFailState(null);

							let newCardState = {
								newState: message.state
							};
							setCardState(newCardState);
						}
					});

					connection.on('ReceiveCancelMessageAsync', message => {
						if (id === message.virtualCardId) {
							setCardFailState(null);

							let newCardState = {
								newState: message.state
							};
							setCardState(newCardState);
						}
					});

					connection.on('ReceiveFailMessageAsync', message => {
						if (id === message.virtualCardId) {
							setCardState(null);

							let newCardFailState = {
								newState: message.state,
								newReason: message.reason
							};
							setCardFailState(newCardFailState);
						}
					});

					connection.on('ReceiveFailedRefreshMessageAsync', message => {
						if (id === message.virtualCardId) {
							setCardState(null);

							let newCardFailState = {
								newReason: message.failureReason
							};
							setCardFailState(newCardFailState);
						}
					});

					connection.on('ReceiveFailedCancelMessageAsync', message => {
						if (id === message.virtualCardId) {
							setCardState(null);

							let newCardFailState = {
								newState: message.state,
								newReason: message.failureReason
							};
							setCardFailState(newCardFailState);
						}
					});
				})
				.catch(e => console.log('Connection failed: ', e));
		}

		return function () {
			if (connection && connection.connectionState === "Connected") {
				connection.stop();
			}
		};
	}, [connection, id]);

	const handleRefreshClick = (data) => {
		if (data) {
			dataProvider.refreshVirtualCard(data);
		}
	};

	const renderRefreshAlert = () => {
		setShowRefreshAlert(true);
		setTimeout(function () {
			setShowRefreshAlert(false);
		}, 2000);
	};

	// Return true if the virtual card can be refreshed. AirPlus is currently not supported.
	const refreshSupported = (data) => {
		if (data) {
			return data.provider !== 'AirPlusFlight' && data.provider !== 'AirPlusHotel' && data.provider !== 'AirPlusProcurement';
		}
		else {
			return false;
		}
	};

	const Title = () => {
		const record = useRecordContext();
		return <span>Virtual Card - ID {record ? record.id : ''}</span>;
	};

	const onSuccess = () => {
		notify(`${translate('kuggar.virtual_cards.on_delete_success_notify')}`);
		redirect(`/virtual_card`);
	};

	const onError = (data) => {
		notify(`${translate('kuggar.virtual_cards.on_delete_error_notify')}: ${data.body.error}`, { type: 'error' });
	};

	const Actions = () => {
		const data = useRecordContext();

		if (data !== undefined && data.state === 'Cancelled') {
			if (HasUpdatePermission('VirtualCards', '/virtual_card/refresh') && refreshSupported(data)) {
				return (
					<TopToolbar>
						<Button
							label={translate('kuggar.virtual_cards.refresh')}
							startIcon={<RefreshIcon />}
							onClick={() => handleRefreshClick(data)} />
					</TopToolbar>
				);
			}
			return <br />;
		}

		return (
			<TopToolbar>
				{HasUpdatePermission('VirtualCards', '/virtual_card/refresh') && refreshSupported(data) ?
					<Button
						label={translate('kuggar.virtual_cards.refresh')}
						startIcon={<RefreshIcon />}
						onClick={() => handleRefreshClick(data)} />
					: null}
				{HasUpdatePermission('VirtualCards', '/virtual_card') ?
					<EditButton
						label={translate('kuggar.virtual_cards.update_card')}
						record={data} />
					: null}
				{HasDeletePermission('VirtualCards', '/virtual_card') ?
					<DeleteButton
						label={translate('kuggar.virtual_cards.cancel_card')}
						record={data}
						icon={<CancelIcon />}
						mutationMode="optimistic"
						mutationOptions={{ onSuccess, onError }}
						confirmTitle={translate('kuggar.virtual_cards.cancel_card')}
						confirmContent={translate('kuggar.virtual_cards.cancel_card_confirmation', { id: data ? data.id : '', hostName: Environment.HostName })} />
					: null}
			</TopToolbar>
		);
	};

	const StateField = (props) => {
		const record = useRecordContext();
		const { state } = record;
		let newState = state;
		let color = 'black';

		if (props != null && props.cardState && props.cardState.newState) {
			newState = cardState.newState;
		}
		else if (props.cardFailState && props.cardFailState.newState) {
			newState = cardFailState.newState;
			color = 'red';
		}
		else if (state && state.startsWith('Failed')) {
			color = 'red'
		}

		return <FunctionField label={props.label} render={record => <span style={{color}}>{newState}</span>} />;
	};

	const CardLimitField = (props) => {
		if (props != null && props.cardState && props.cardState.newCardLimit) {
			return <FunctionField label={props.label} render={record => record.currentCardProperties && record.currentCardProperties.currencyCodes
				? FormatCurrencyValue(record.currentCardProperties.currencyCodes, cardState.newCardLimit)
				: cardState.newCardLimit}
			/>;
		}
		else {
			return <FunctionField label={props.label} render={record => record.currentCardProperties.currencyCodes
				? FormatCurrencyValue(record.currentCardProperties.currencyCodes, record.currentCardProperties.cardLimit)
				: record.cardLimit}
			/>;
		}
	};

	const AvailableBalanceField = (props) => {
		if (props != null && props.cardState && props.cardState.newAvailableBalance) {
			return <FunctionField label={props.label} render={record => record.currentCardProperties && record.currentCardProperties.currencyCodes
				? FormatCurrencyValue(record.currentCardProperties.currencyCodes, cardState.newAvailableBalance)
				: cardState.newAvailableBalance}
			/>;
		}
		else {
			return <FunctionField label={props.label} render={record => record.currentCardProperties.currencyCodes
				? FormatCurrencyValue(record.currentCardProperties.currencyCodes, record.currentCardProperties.availableBalance)
				: record.availableBalance}
			/>;
		}
	};

	const ValidFromField = (props) => {
		if (props != null && props.cardState && props.cardState.newValidFrom) {
			return <FunctionField label={props.label} render={record => moment(new Date(cardState.newValidFrom)).format('YYYY-MM-DD')} />;
		}
		else {
			return <DateField label={props.label} source="currentCardProperties.validFrom" locales={DateTimeLocale} />;
		}
	};

	const ValidToField = (props) => {
		if (props != null && props.cardState && props.cardState.newValidTo) {
			return <FunctionField label={props.label} render={record => moment(new Date(cardState.newValidTo)).format('YYYY-MM-DD')} />;
		}
		else {
			return <DateField label={props.label} source="currentCardProperties.validTo" locales={DateTimeLocale} />;
		}
	};

	const LastSynchDateUtcField = (props) => {
		if (props != null && props.cardState && props.cardState.newLastSynchDateUtc) {
			return <FunctionField label={props.label} render={record => moment(new Date(cardState.newLastSynchDateUtc)).format('YYYY-MM-DD HH:mm:ss')} />;
		}
		else {
			return <DateField label={props.label} source="currentCardProperties.dateTimeUtc" showTime locales={DateTimeLocale} />;
		}
	};

	const FailureReasonField = (props) => {
		if (props != null && props.cardFailState && props.cardFailState.newReason) {
			return <FunctionField label={props.label} render={record => `${cardFailState.newReason}`} />;
		}
		else {
			return <ConditionalTextField source={props.source} label={props.label} emptyText="None" />;
		}
	};

	const currentSort = { field: 'label', order: 'ASC' };
	const UserDefinedFields = (props) => {
		if (props != null && props.cardState && props.cardState.newUserDefinedFields) {
			return (
				<ArrayField record={props.cardState} source="newUserDefinedFields" label={props.label} sort={currentSort}>
					<Datagrid classes={props.classes} bulkActionButtons={false}>
						<TextField source="label" label={translate('kuggar.shared.name')} />
						<TextField source="value" label={translate('kuggar.shared.value')} />
					</Datagrid>
				</ArrayField>
			)
		}
		else {
			return (
				<ArrayField source="currentCardProperties.userDefinedFields" label={props.label} sort={currentSort}>
					<Datagrid classes={props.classes} bulkActionButtons={false}>
						<TextField source="label" label={translate('kuggar.shared.name')} />
						<TextField source="value" label={translate('kuggar.shared.value')} />
					</Datagrid>
				</ArrayField>
			);
		}
	};

	const ConditionalJsonField = (props) => {
		const { source, label, jsonString, reactJsonOptions } = props;
		const record = useRecordContext();
		if (record[source] === undefined || record[source] === "") return null;
		return <JsonField record={record} source={source} label={label} jsonString={jsonString} reactJsonOptions={reactJsonOptions} />
	};

	const CardViewField = (props) => {
		const { label } = props;
		const record = useRecordContext();

		const d = new Date(record.currentCardProperties.validTo);
		var month = d.getMonth() + 1;
		if (month < 10) month = "0" + month;
		var year = d.getFullYear().toString().slice(-2);

		let cardType = 'CA';
		if (record.provider === 'Mint') {
			if (record.createResponse) {
				const createResponse = JSON.parse(record.createResponse);
				const cardBrand = createResponse.CardDetails.CardBrand.toLowerCase();

				if (cardBrand === 'visa')
					cardType = 'VI';

				month =	createResponse.CardDetails.ExpiryDateMonth;
				year = createResponse.CardDetails.ExpiryDateYear.substr(2, 2);
			}
			else if (record.currentCardProperties && record.currentCardProperties.cardNumber && record.currentCardProperties.cardNumber.startsWith('4')) {
				cardType = 'VI';
			}
		}
		else if (record.provider === 'Kuggar') {
			if (record.currentCardProperties && record.currentCardProperties.cardNumber && record.currentCardProperties.cardNumber.startsWith('1')) {
				cardType = 'TP';
			}
		}

		// Card number plain
		const cardNumberPlain = {
			id: 'cardNumberPlain',
			value: record.currentCardProperties.cardNumber
		};

		// Card number spaces
		const cardNumberSpaces = {
			id: 'cardNumberSpaces',
			value: FormatCardNumber(record.currentCardProperties.cardNumber)
		};

		// Mastercard Amadeus CCCAzzzzzzzzzzzzzzzzEXPmmyy
		const masterCardAmadeus = {
			id: 'masterCardAmadeus',
			value: `CC${cardType}${record.currentCardProperties.cardNumber}EXP${month}${year}`
		};

		// Mastercard Travelport/Sabre CAzzzzzzzzzzzzzzzzEXPmmyy
		const masterCardTravelportSabre = {
			id: 'masterCardTravelportSabre',
			value: `${cardType}${record.currentCardProperties.cardNumber}EXP${month}${year}`
		};

		const cardFormats = [cardNumberPlain, cardNumberSpaces, masterCardAmadeus, masterCardTravelportSabre];

		const onFormatSelected = e => {
			const selectedId = e.target.value;
			const selectedValue = cardFormats.find(x => x.id === selectedId).value;

			navigator.clipboard.writeText(selectedValue);

			setSelectedCopyValue(selectedValue);
			setShowCopyAlert(true);

			setTimeout(function () {
				setSelectedCopyValue(null);
				setShowCopyAlert(false);
			}, 2000);
		};

		return (
			<>
				<CardView record={record} label={label} />
				<Form noValidate>
					<SelectInput
						source="clipboard-copy"
						label="Card Formats"
						choices={cardFormats}
						optionText="value"
						helperText="Select a card format to copy to the clipboard"
						sx={{width:350}}
						onChange={onFormatSelected}
					/>
					<Collapse in={showCopyAlert}>
						<Alert severity="info" onClose={() => {
							setSelectedCopyValue(null);
							setShowCopyAlert(false);
						}}>
							'{selectedCopyValue}' copied to clipboard
						</Alert>
					</Collapse>
				</Form>
			</>
		);
	};

	const ProviderWithIdField = () => {
		const record = useRecordContext();

		const ProviderImageField = props => <ImageField source="provider" path="cards" height="50" {...props} />;

		if (record.currentCardProperties.providerId === null || record.currentCardProperties.providerId === "") {
			return (
				<Labeled label={translate('kuggar.virtual_cards.provider')}>
					<ProviderImageField label={translate('kuggar.virtual_cards.provider')} />
				</Labeled>
			);
		}

		let providerLabel = translate('kuggar.virtual_cards.provider_id');
		if (record.provider === "WEX")
			providerLabel = translate('kuggar.virtual_cards.provider_id_wex');
		else if (record.provider === "eNett")
			providerLabel = translate('kuggar.virtual_cards.provider_id_eNett');
		else if (record.provider === "Mastercard")
			providerLabel = translate('kuggar.virtual_cards.provider_id_mastercard');
		else if (record.provider === "Mint")
			providerLabel = translate('kuggar.virtual_cards.provider_id_mint');

		return (
			<Table size="small" sx={{width:'350px'}}>
				<TableRow>
					<TableCell sx={{width:'80px',border:0,padding:'0 10px 3px 0',fontSize:'0.75em',lineHeight:'1.5',letterSpacing:'0.00938em',color:'rgba(0, 0, 0, 0.6)'}}>
						{translate('kuggar.virtual_cards.provider')}
					</TableCell>
					<TableCell sx={{border:0,padding:'0 10px 3px 0',fontSize:'0.75em',lineHeight:'1.5',letterSpacing:'0.00938em',color:'rgba(0, 0, 0, 0.6)'}}>
						{providerLabel}
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell sx={{width:'80px',border:0,padding:'0 10px 0 0',verticalAlign:'center',fontSize:'1em'}}>
						<ProviderImageField />
					</TableCell>
					<TableCell sx={{border:0,padding:0,verticalAlign:'center',fontSize:'1em'}}>
						<TextFieldWithCopyButton source="currentCardProperties.providerId" />
					</TableCell>
				</TableRow>
			</Table>
		);
	};

	const CurrenciesWithCardLimitField = () => {
		const record = useRecordContext();

		let CurrencyField = () => (
			<Labeled label={translate('kuggar.shared.currencies')}>
				<ArrayField source="currentCardProperties.currencyCodes">
					<SingleFieldList linkType={false}>
						<CurrencyChipField />
					</SingleFieldList>
				</ArrayField>
			</Labeled>
		);

		if (record && record.currentCardProperties && record.currentCardProperties.currencyCodes.length === 1) {
			CurrencyField = () => (
				<Labeled label={translate('kuggar.shared.currency')}>
					<FunctionField render={record => <span style={{verticalAlign:'top'}}><CurrencyFlag currency={record.currentCardProperties.currencyCodes[0]} size="md" style={{verticalAlign:'top'}} />&nbsp;{record.currentCardProperties.currencyCodes[0]}</span>} />
				</Labeled>
			);
		}

		const showAvailableBalance = record && record.provider === 'Mint';

		return (
			<Table size="small" sx={{width:'350px'}}>
				<TableRow>
					<TableCell sx={{width:'130px',border:0,padding:0,verticalAlign:'top',fontSize:'1em'}}>
						<CurrencyField />
					</TableCell>
					<TableCell sx={{width: showAvailableBalance ? '110px' : '220px',border:0,padding:0,verticalAlign:'top',fontSize:'1em'}}>
						<Labeled label={translate('kuggar.virtual_cards.card_limit')}>
							<CardLimitField cardState={cardState} />
						</Labeled>
					</TableCell>
					{showAvailableBalance && (
						<TableCell sx={{width:'110px',border:0,padding:0,verticalAlign:'top',fontSize:'1em'}}>
							<Labeled label={translate('kuggar.virtual_cards.available_balance')}>
								<AvailableBalanceField cardState={cardState} />
							</Labeled>
						</TableCell>
					)}
				</TableRow>
			</Table>
		);
	};

	const ValidityField = () => (
		<Table size="small" sx={{width:'350px'}}>
			<TableRow>
				<TableCell sx={{width:'130px',border:0,padding:0,verticalAlign:'top',fontSize:'1em'}}>
					<Labeled label={translate('kuggar.virtual_cards.valid_from')}>
						<ValidFromField cardState={cardState} />
					</Labeled>
				</TableCell>
				<TableCell sx={{width:'220px',border:0,padding:0,verticalAlign:'top',fontSize:'1em'}}>
					<Labeled label={translate('kuggar.virtual_cards.valid_to')}>
						<ValidToField cardState={cardState} />
					</Labeled>
				</TableCell>
			</TableRow>
		</Table>
	);

	const LinkedLogs = () => {
		const record = useRecordContext();
		let { data, isLoading } = useGetList(
			'linked_logs',
			{
				pagination: { page: 1, perPage: 1000 },
				sort: { field: 'Timestamp', order: 'DESC' },
				filter: { linkedEntityName: 'VirtualCard', linkedEntityId: record.id }
			}
		);
		if (isLoading) return <span>Loading</span>;

		const createCompositeKey = (obj) => {
			return Object.values(obj).join('_');
		}

		// Add update history to logs
		if (record.updateHistory) {
			record.updateHistory.forEach(x => data.push({
				id: createCompositeKey(x),
				entry: {
					logId: x.id,
					timestampUtc: x.dateTimeUtc,
					type: 'Application',
					level: 'Information',
					message: `Card updated by Access Profile ID ${x.accessProfileId}`,
					data: x.serializedUpdateRequest
				}
			}));
		}

		// Add update failure history to logs
		if (record.updateFailureHistory) {
			record.updateFailureHistory.forEach(x => data.push({
				id: createCompositeKey(x),
				entry: {
					logId: x.id,
					timestampUtc: x.dateTimeUtc,
					type: 'Application',
					level: 'Error',
					message: `Card update failed by Access Profile ID ${x.accessProfileId}. Reason: ${x.failureReason}`,
					data: x.serializedUpdateRequest
				}
			}));
		}

		// Remove duplicates
		data = data.filter((value, index, self) =>
			self.findIndex(item => item.id === value.id) === index)

		// Sort by timestamp ascending
		data.sort((a,b) => new Date(a.entry.timestampUtc) - new Date(b.entry.timestampUtc));

		const linkedLogs = { entries: data };
		const logStyle = { whiteSpace: 'pre-wrap' };

		return (
			<>
				<ArrayField record={linkedLogs} source="entries" label={false}>
					<Datagrid
						rowClick="expand"
						expand={(
							<>
								<ConditionalTextField source="entry.data" label={translate('kuggar.logs.data')} emptyText="None" style={logStyle} />
								<ConditionalTextField source="entry.exception" label={translate('kuggar.logs.exception')} emptyText="None" style={logStyle} />
							</>
						)}
						isRowExpandable={record => record.entry.data || record.entry.exception}
						bulkActionButtons={false}
					>
						<DateField source="entry.timestampUtc" label={translate('kuggar.logs.timestamp')} showTime locales={DateTimeLocale} sortable={false} />
						<TextField source="entry.type" label={translate('kuggar.logs.type')}  />
						<TextField source="entry.level" label={translate('kuggar.logs.level')}  sortable={false} />
						<TextField source="entry.message" label={translate('kuggar.logs.message')} sortable={false} />
					</Datagrid>
				</ArrayField>
				<br/>
			</>
		);
	};

	const CardHistory = () => {
		const record = useRecordContext();

		const EmptyScheduledLoads = () => <div class="MuiTypography-root MuiTypography-body2">No scheduled loads available.</div>;
		const EmptyTransactions = () => <div class="MuiTypography-root MuiTypography-body2">No transactions available.</div>;
		const EmptyAuthorisations = () => <div class="MuiTypography-root MuiTypography-body2">No authorisations available.</div>;

		return (
			<>
				<Labeled label={translate('kuggar.prepaid_accounts.scheduled_loads')}>
					{record.provider === 'Mint' ? (
						<List resource="vc_scheduled_loads" filter={{ virtualCardId: record.id }} sort={{ field: 'timestampUtc', order: 'DESC' }} title=" " actions={null} empty={<EmptyScheduledLoads />} disableSyncWithLocation>
							<Datagrid
								rowClick="expand"
								expand={(
									<Labeled label={translate('kuggar.prepaid_accounts.scheduled_load_failure_reason')}>
										<TextField source="failureReason" />
									</Labeled>
								)}
								isRowExpandable={record => record.failureReason}
								bulkActionButtons={false}
							>
								<DateField source="timestampUtc" label={translate('kuggar.shared.timestamp')} showTime locales={DateTimeLocale} sortable={false} />
								<TextField source="providerReference" label={translate('kuggar.shared.reference_code')} sortable={false} />
								<TextField source="state" label={translate('kuggar.shared.state')} sortable={false} />
								<FunctionField render={record => <span><CurrencyFlag currency={record.currencyCode} size="md" />&nbsp;{record.currencyCode}</span>} label={translate('kuggar.shared.currency')} />
								<FunctionField render={record => FormatCurrencyValue(record.currencyCode, record.amount)} label={translate('kuggar.prepaid_accounts.scheduled_load_amount')} />
								<DateField source="scheduleDateUtc" label={translate('kuggar.prepaid_accounts.scheduled_load_schedule_date')} locales={DateTimeLocale} sortable={false} />
								<DateField source="clearanceDateUtc" label={translate('kuggar.prepaid_accounts.scheduled_load_clearance_date')} locales={DateTimeLocale} sortable={false} />
							</Datagrid>
						</List>
					) : (
						<EmptyScheduledLoads />
					)}
				</Labeled>
				<Labeled label={translate('kuggar.vc_transactions.transactions')}>
					{record.provider === 'Mint' || record.provider === 'WEX' ? (
						<List resource="vc_transactions" filter={{ virtualCardId: record.id }} sort={{ field: 'timestampUtc', order: 'DESC' }} title=" " actions={null} empty={<EmptyTransactions />} disableSyncWithLocation>
							<Datagrid
								rowClick="expand"
								expand={(
									<Labeled label={translate('kuggar.shared.description')}>
										<TextField source="description" />
									</Labeled>
								)}
								isRowExpandable={record => record.description}
								bulkActionButtons={false}
							>
								<DateField source="timestampUtc" label={translate('kuggar.shared.timestamp')} showTime locales={DateTimeLocale} sortable={false} />
								<TextField source="providerReference" label={translate('kuggar.shared.reference_code')} sortable={false} />
								<TextField source="type" label={translate('kuggar.shared.type')} sortable={false} />
								<FunctionField render={record => <span><CurrencyFlag currency={record.currencyCode} size="md" />&nbsp;{record.currencyCode}</span>} label={translate('kuggar.shared.currency')} />
								<FunctionField render={record => FormatCurrencyValue(record.currencyCode, record.credited)} label={translate('kuggar.vc_transactions.credited')} />
								<FunctionField render={record => FormatCurrencyValue(record.currencyCode, record.debited)} label={translate('kuggar.vc_transactions.debited')} />
								<FunctionField render={record => FormatCurrencyValue(record.currencyCode, record.balance)} label={translate('kuggar.vc_transactions.balance')} />
								<FunctionField render={record => FormatCurrencyValue(record.currencyCode, record.fee)} label={translate('kuggar.vc_transactions.fee')} />
								<FunctionField render={record => record.forexAmount == null ? null : <span><CurrencyFlag currency={record.forexCurrencyCode} size="md" />&nbsp;{record.forexCurrencyCode}</span>} label={translate('kuggar.vc_transactions.forexCurrency')} />
								<FunctionField render={record => FormatCurrencyValue(record.currencyCode, record.forexRate)} label={translate('kuggar.vc_transactions.forexRate')} />
								<FunctionField render={record => FormatCurrencyValue(record.currencyCode, record.forexFee)} label={translate('kuggar.vc_transactions.forexFee')} />
								<FunctionField render={record => FormatCurrencyValue(record.currencyCode, record.forexAmount)} label={translate('kuggar.vc_transactions.forexAmount')} />
							</Datagrid>
						</List>
					) : (
						<EmptyTransactions />
					)}
				</Labeled>
				<Labeled label={translate('kuggar.vc_authorisations.authorisations')}>
					{record.provider === 'Mint' || record.provider === 'WEX' || record.provider.startsWith('AirPlus') ? (
						<List resource="vc_authorisations" filter={{ virtualCardId: record.id }} sort={{ field: 'timestampUtc', order: 'DESC' }} title=" " actions={null} empty={<EmptyAuthorisations />} disableSyncWithLocation>
							<Datagrid bulkActionButtons={false}>
								<DateField source="timestampUtc" label={translate('kuggar.shared.timestamp')} showTime locales={DateTimeLocale} sortable={false} />
								{record.provider === 'Mint' ? <TextField source="providerReference" label={translate('kuggar.shared.reference_code')} sortable={false} /> : null}
								{record.provider === 'WEX' ? <TextField source="authCode" label={translate('kuggar.vc_authorisations.auth_code')} sortable={false} /> : null}
								<FunctionField render={record => <span style={{color: ['0','00','8','08','11','85'].includes(record.responseCode) ? 'green' : 'red'}}>{record.state}</span>} label={translate('kuggar.shared.state')} />
								<FunctionField render={record => <span>{record.merchantName} <a href={`https://www.google.com/search?q=${encodeURIComponent(record.merchantName)}`} target="_blank" rel="noreferrer"><LaunchIcon color="primary" fontSize="small" /></a></span>} label={translate('kuggar.vc_authorisations.merchant_name')} />
								{record.provider === 'Mint' ? <TextField source="adjustmentType" label={translate('kuggar.vc_authorisations.adjustment_type')} sortable={false} /> : null}
								<FunctionField render={record => <span><CurrencyFlag currency={record.transactionCurrencyCode} size="md" />&nbsp;{record.transactionCurrencyCode}</span>} label={translate('kuggar.shared.currency')} />
								<FunctionField render={record => FormatCurrencyValue(record.transactionCurrencyCode, record.transactionAmount)} label={translate('kuggar.vc_authorisations.transaction_amount')} />
								<FunctionField render={record => FormatCurrencyValue(record.cardCurrencyCode, record.billingAmount)} label={translate('kuggar.vc_authorisations.billing_amount')} />
								<FunctionField render={record => <span><Flag code={record.terminalCountryCode} height="16" width="24" />&nbsp;{record.terminalCountryCode}</span>} label={translate('kuggar.vc_authorisations.terminal_country_code')} />
								<FunctionField render={record => <span style={{color: ['0','00','8','08','11','85'].includes(record.responseCode) ? 'green' : 'red'}}>{record.responseCode}</span>} label={translate('kuggar.vc_authorisations.response_code')} />
								<FunctionField render={record => {
									const response = responseCodes.find(x => x.code === record.responseCode);
									if (response)
										return <span style={{color: ['0','00','8','08','11','85'].includes(response.code) ? 'green' : 'red'}}>{response.description}</span>;
									return <span>Undefined</span>;
								}} label={translate('kuggar.vc_authorisations.response_description')} />
							</Datagrid>
						</List>
					) : (
						<EmptyAuthorisations />
					)}
				</Labeled>
			</>
		);
	};

	return (
		<Show title={<Title />} actions={<Actions />}>
			<TabbedShowLayout syncWithLocation={false}>
				<Tab label={translate('kuggar.virtual_cards.tab_summary')}>
					<Collapse in={showRefreshAlert}>
						<Alert onClose={() => setShowRefreshAlert(false)}>Card refreshed</Alert>
					</Collapse>
					<TextFieldWithCopyButton source='id' label={translate('kuggar.shared.kuggar_id', Environment)} />
					<FunctionField label={translate('kuggar.organisation.organisation')} render={record => record.organisation ? `${record.organisation.name} (${record.organisation.referenceCode})` : '-'} />
					<ReferenceField source="issuerAccountId" label={translate('kuggar.shared.issuer_account')} reference="issuer_account" link="show">
						<TextField source="description" />
					</ReferenceField>
					<ProviderWithIdField />
					<StateField cardState={cardState} cardFailState={cardFailState} label={translate('kuggar.virtual_cards.state')} />
					<LastSynchDateUtcField cardState={cardState} label={translate('kuggar.virtual_cards.last_refreshed')} />
					<CardViewField label={translate('kuggar.virtual_cards.virtual_card')} />
					<CurrenciesWithCardLimitField />
					<ValidityField />
					<UserDefinedFields cardState={cardState} label={translate('kuggar.virtual_cards.user_defined_fields')} classes={classes} />
				</Tab>
				<Tab label={translate('kuggar.virtual_cards.tab_details')} path="details">
					<Collapse in={showRefreshAlert}>
						<Alert onClose={() => setShowRefreshAlert(false)}>Card refreshed</Alert>
					</Collapse>
					<FailureReasonField source="failureReason" cardFailState={cardFailState} label={translate('kuggar.virtual_cards.failure_reason')} />
					<ConditionalJsonField source="createResponse" label={translate('kuggar.virtual_cards.created_card_data')} jsonString={true} reactJsonOptions={{ name: null, collapsed: false, enableClipboard: false, displayDataTypes: false }} />
					<ConditionalJsonField source="updateResponse" label={translate('kuggar.virtual_cards.last_updated_card_data')} jsonString={true} reactJsonOptions={{ name: null, collapsed: false, enableClipboard: false, displayDataTypes: false }} />
				</Tab>
				<Tab label={translate('kuggar.virtual_cards.tab_history')} path="history">
					<CardHistory />
				</Tab>
				<Tab label={translate('kuggar.virtual_cards.tab_logs')} path="logs">
					<LinkedLogs />
				</Tab>
			</TabbedShowLayout>
		</Show>
	);
};

export const VirtualCardEdit = () => {
	const translate = useTranslate();
	const Title = () => {
		const record = useRecordContext();
		return <span>{translate('kuggar.virtual_cards.title_update')} - {translate('kuggar.shared.id')} {record ? record.id : ''}</span>;
    };
    
    

	// Edit card form validation
	const validateCardEdit = (values) => {
		const errors = {};

		if (values.validFrom && values.validTo) {
			const validFromDt = Date.parse(values.validFrom);
			const validToDt = Date.parse(values.validTo);
			if (validToDt <= validFromDt) {
				errors.validTo = translate('kuggar.virtual_cards.valid_to_validation');
            }

            const validToDateMax = new Date(validFromDt.getFullYear() + 1, validFromDt.getMonth(), validFromDt.getDate());
            if (validToDt > validToDateMax) {
				errors.validTo = translate('kuggar.virtual_cards.valid_to_max_validation');
            }

		}

		if (values.cardLimit === 0) {
			errors.cardLimit = translate('kuggar.virtual_cards.card_limit_validation');
		}

		return errors;
	};

	const inputStyle = { width: '512px' };

	return (
		<Edit title={<Title />} mutationMode="optimistic">
			<SimpleForm toolbar={<UpdateToolbar />} validate={validateCardEdit}>
				<TextInput
					source="id"
					label={translate('kuggar.virtual_cards.system_card_id')}
					disabled />
				<NumberInput
					source="currentCardProperties.cardLimit"
					label={translate('kuggar.virtual_cards.card_limit_update')}
					min="1"
					helperText={translate('kuggar.virtual_cards.card_limit_helper')}
					style={inputStyle} />
				<DateInput
					source="currentCardProperties.validFrom"
					label={translate('kuggar.virtual_cards.valid_from')}
					style={inputStyle} />
				<DateInput
					source="currentCardProperties.validTo"
					label={translate('kuggar.virtual_cards.valid_to')}
					style={inputStyle} />
				<UserDefinedFieldsUpdate
					source="currentCardProperties.userDefinedFields"
					style={inputStyle} />
			</SimpleForm>
		</Edit>
	);
};

export const VirtualCardCreate = () => {
	const notify = useNotify();
	const redirect = useRedirect();
	const translate = useTranslate();

	// Create card form validation
	const validateCardCreate = (values) => {
		const errors = {};

		if (values.validFrom && values.validTo) {
			const validFromDt = Date.parse(values.validFrom);
			const validToDt = Date.parse(values.validTo);
			if (validToDt <= validFromDt) {
				errors.validTo = translate('kuggar.virtual_cards.valid_to_validation');
			}
		}

		if (values.cardLimit === 0) {
			errors.cardLimit = translate('kuggar.virtual_cards.card_limit_validation');
		}

		return errors;
	};

	const onSuccess = (data) => {
		notify(`${translate('kuggar.virtual_cards.on_success_notify')} ${data.provider}`);
		redirect(`/virtual_card/${data.id}/show`);
	};

	const onError = (data) => {
		notify(data.body.error, { type: 'error' });
	};

	const inputStyle = { width: '512px' };

	const GetCacheCurrency = (cacheId) => {
		const { data: cache, isLoading, error } = useGetOne('cache', { id: cacheId, meta: { is_vc_create: true } });
		if (isLoading || error) return null;
		return cache.currencyCodes && cache.currencyCodes.length > 0 ? cache.currencyCodes[0] : null;
	}

	const CurrencyInput = props => {
		const cacheId = useWatch({ name: 'cacheId' });

		return <CurrencySelect defaultValue={GetCacheCurrency(cacheId)} {...props} />;
	};

	const ValidToInput = props => {
        const validFrom = useWatch({ name: 'validFrom' });
        let validTo = null;
        let validToMax = null;

		if (validFrom)
		{
			const validFromDate = new Date(validFrom);
            const validToDate = new Date(validFromDate.setDate(validFromDate.getDate() + 1));
            validTo = validToDate.toISOString().split('T')[0];
            const validToMaxDate = new Date(validFromDate.getFullYear() + 1, validFromDate.getMonth(), validFromDate.getDate()-1);
            validToMax = validToMaxDate.toISOString().split('T')[0];
        }
        else
        {
            const currentDate = new Date();
            const validToMaxDate = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate());
            validToMax = validToMaxDate.toISOString().split('T')[0];
        }

       return <DateInput defaultValue={validTo} {...props} validate={[required(), maxValue(validToMax)]} />;
	};		

	const createDefaultValues = () => ({ method: 'Basic' });

	return (
		<Create title={translate('kuggar.virtual_cards.title_create')} mutationOptions={{ onSuccess, onError }}>
			<SimpleForm toolbar={<CreateToolbar />} validate={validateCardCreate} defaultValues={createDefaultValues}>
				<RadioButtonGroupInput
					source="method"
					label={translate('kuggar.virtual_cards.create_method')}
					helperText={false}
					choices={[
						{ id: 'Basic', name: 'Basic' },
						{ id: 'Advanced', name: 'Advanced' }
					]}
					validate={[required()]} />
				<FormDataConsumer>
					{({ formData }) => formData.method === 'Advanced' && (
						<ReferenceInput source="cacheId" reference="cache">
							<SelectInput
								optionText="description"
								label={translate('kuggar.virtual_cards.source_cache')}
								helperText={translate('kuggar.virtual_cards.source_cache_helper')}
								validate={[required()]}
								style={inputStyle} />
						</ReferenceInput>
					)}
				</FormDataConsumer>
				<CurrencyInput
					source="currencyCode"
					value="alphaCode"
					label={translate('kuggar.shared.currency')}
					helperText={false}
					style={inputStyle} />
				<NumberInput
					source="cardLimit"
					label={translate('kuggar.virtual_cards.card_limit_create')}
					min="1"
					helperText={translate('kuggar.virtual_cards.card_limit_helper')}
					validate={[required()]}
					style={inputStyle} />
				<DateInput
					source="validFrom"
					label={translate('kuggar.virtual_cards.valid_from')}
					validate={[required()]}
					style={inputStyle} />
				<ValidToInput
					source="validTo"
					label={translate('kuggar.virtual_cards.valid_to')}
					style={inputStyle} />
				<UserDefinedFieldsCreate
					source="userDefinedFields"
					style={inputStyle} />
			</SimpleForm>
		</Create>
	);
}

